exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-data-policy-js": () => import("./../../../src/pages/data-policy.js" /* webpackChunkName: "component---src-pages-data-policy-js" */),
  "component---src-pages-good-practices-js": () => import("./../../../src/pages/good-practices.js" /* webpackChunkName: "component---src-pages-good-practices-js" */),
  "component---src-pages-good-practices-submit-js": () => import("./../../../src/pages/good-practices/submit.js" /* webpackChunkName: "component---src-pages-good-practices-submit-js" */),
  "component---src-pages-good-practices-user-guide-js": () => import("./../../../src/pages/good-practices-user-guide.js" /* webpackChunkName: "component---src-pages-good-practices-user-guide-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-js": () => import("./../../../src/pages/legal.js" /* webpackChunkName: "component---src-pages-legal-js" */),
  "component---src-pages-lessons-js": () => import("./../../../src/pages/lessons.js" /* webpackChunkName: "component---src-pages-lessons-js" */),
  "component---src-pages-library-js": () => import("./../../../src/pages/library.js" /* webpackChunkName: "component---src-pages-library-js" */),
  "component---src-pages-partners-js": () => import("./../../../src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */),
  "component---src-pages-platform-guide-js": () => import("./../../../src/pages/platform-guide.js" /* webpackChunkName: "component---src-pages-platform-guide-js" */),
  "component---src-pages-project-js": () => import("./../../../src/pages/project.js" /* webpackChunkName: "component---src-pages-project-js" */),
  "component---src-pages-project-logs-js": () => import("./../../../src/pages/project-logs.js" /* webpackChunkName: "component---src-pages-project-logs-js" */),
  "component---src-pages-training-accessibility-js": () => import("./../../../src/pages/training/accessibility.js" /* webpackChunkName: "component---src-pages-training-accessibility-js" */),
  "component---src-pages-training-ecodesign-js": () => import("./../../../src/pages/training/ecodesign.js" /* webpackChunkName: "component---src-pages-training-ecodesign-js" */),
  "component---src-pages-training-implementation-guide-js": () => import("./../../../src/pages/training-implementation-guide.js" /* webpackChunkName: "component---src-pages-training-implementation-guide-js" */),
  "component---src-pages-training-js": () => import("./../../../src/pages/training.js" /* webpackChunkName: "component---src-pages-training-js" */),
  "component---src-pages-training-project-management-js": () => import("./../../../src/pages/training/project-management.js" /* webpackChunkName: "component---src-pages-training-project-management-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-good-practices-js": () => import("./../../../src/templates/good-practices.js" /* webpackChunkName: "component---src-templates-good-practices-js" */),
  "component---src-templates-lessons-js": () => import("./../../../src/templates/lessons.js" /* webpackChunkName: "component---src-templates-lessons-js" */),
  "component---src-templates-project-logs-js": () => import("./../../../src/templates/project-logs.js" /* webpackChunkName: "component---src-templates-project-logs-js" */)
}

