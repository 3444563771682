module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"D:\\websites\\tgm-website\\thegoodmanager/src/intl","languages":["en","fr","it","es","bg"],"defaultLanguage":"en","redirect":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"The Good Manager","short_name":"TGM","start_url":"/","background_color":"#00d8ae","theme_color":"#000000","display":"standalone","icon":"src/images/favicons/favicon-192px_2.png","crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"8ea494ade2a3c99a48ca7ad2fb67f79c"},
    },{
      plugin: require('../node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"2","matomoUrl":"https://thegoodmanager.eu/webanalytics/matomo","siteUrl":"https://thegoodmanager.eu/"},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
